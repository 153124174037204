<template>
    <div class="news-detail">
        <nav-bar class="nav-bar" id="titleArea" ref="nav"></nav-bar>
        <div class="news-content" id="middleArea">
            <div class="title text-over">{{ news.title }}</div>
            <div class="day">发布时间：{{ news.createTime }}</div>
            <div class="desc" v-html='this.news.content'>
            </div>
            <!-- <div>
                <div class="pian">上一篇：如何让冷链园区更“聪明”? 普洛斯海屹设计院的洞察与实践</div>
                <div class="pian">下一篇：如何让冷链园区更“聪明”? 普洛斯海屹设计院的洞察与实践</div>
            </div> -->
        </div>
        <bottom-bar class="news-bottom" />
    </div>
</template>
<script>
import { Message, MessageBox } from 'element-ui'  //导入element-ui组件库
import { PageList, NewsInfo } from '@/api/news'
import { getInfo } from '@/api/login'

import NavBar from '../../components/content/navBar/NavBar'
import BottomBar from '../../components/content/bottomBar/BottomBar'

import { getToken, setToken, removeToken } from '@/utils/cookies.js'

export default {
    name: 'NewsDetail',
    components: {
        NavBar,
        BottomBar
    },
    data() {
        return {
            id: '',
            users: {},//用户信息
            news: {},//详细信息
        }
    },
    created() {
        //用户信息
        this.userInfo();
        //详细信息
        this.onNewsInfo();
    },
    methods: {
        //用户信息
        userInfo() {
            var token = getToken();
            if (token) {
                getInfo(token).then(response => {
                    if (response.status == 200) {
                        this.users = response.data;
                    }
                    setTimeout(() => {
                        this.listLoading = false
                    }, 1.5 * 1000)
                })
            }
        },
        //详细信息
        onNewsInfo() {
            this.listLoading = true
            this.id = this.$route.params.id;
            NewsInfo({ 'id': this.id }).then(response => {
                if (response) {
                    if (response.status == 200) {
                        this.news = response.data;
                    } else {
                        MessageBox.alert(response.message, "错误", {
                            confirmButtonText: '确定',
                            type: 'error'
                        })
                    }
                    setTimeout(() => {
                        this.listLoading = false
                    }, 1.5 * 1000)
                }
            })
        },
    },
    mounted() {
        if (localStorage.getItem("mdd")) {
            this.$refs.nav.mdd = parseInt(localStorage.getItem("mdd"));
        }
        const middleArea = document.getElementById('middleArea');
        const mheight = document.documentElement.clientHeight;
        const titleHeight = document.getElementById('titleArea').clientHeight;
        middleArea.style.minHeight = (Number(mheight) - (Number(titleHeight) + 50)) + 'px';
    }
}
</script>
<style scoped lang="less">
.news-detail {
    min-width: 1000px;

    .nav-bar {
        .bar-icon {
            border-radius: 50%;
            vertical-align: -4px;
            margin-right: 7px;
            width: 1.05rem;
            height: 1.05rem;
        }
    }

    .news-content {
        padding: 1.9rem 1.82rem 1.7rem;
        margin: 1.1rem 9.1rem 2.42rem 9.1rem;
        background-color: #fff;
        overflow-y: auto;
        overflow-x: hidden;

        ::v-deep {

            img,
            p,
            span {
                width: 100%;
            }
        }

        .title {
            color: #303133;
            font-weight: 600;
            text-align: center;
            font-size: 18px;
            margin-bottom: 1.5rem;
        }

        .day {
            color: #999999;
            font-size: 12px;
            text-align: center;
        }

        .desc {
            color: #6A6E76;
            font-size: 12px;
            line-height: 1rem;
            border-bottom: 1px dashed #E7E7E7;
            margin-top: 1.15rem;
            padding-bottom: 1.8rem;
            margin-bottom: 1.3rem;

            img {
                width: 45%;
            }
        }

        .pian {
            color: #6C6F75;
            font-size: 12px;
            margin-bottom: 0.9rem;

            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
}
</style>